import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Box, Checkbox, Link, Typography, Switch, Modal} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import TableContainer from "@mui/material/TableContainer";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import React, { useState, useMemo, useEffect, useCallback } from 'react'
import ItemsProductionAccordionItem from "./ItemsProductionAccordionItem";
import OrderChangeTableBodyModal
    from "../../../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTableBody/OrdersChangeTableBodyModal/OrderChangeTableBodyModal";

const demoSession = {
    user: {
        name: 'Bharat Kashyap',
        email: 'bharatkashyap@outlook.com',
        image: 'https://avatars.githubusercontent.com/u/19550456',
    },
};


const ItemsProductionAccordion = ({setSelectedItems, drivers, uniqueProveedors, rowStyle, checkBoxStyle, rowStyleMap, statusBackground, switchStyle, fakeRows, selectedItems, allItemsArray, handleTopLevelSelect, handleAllItemsSelect, setSwitchChecker, switchChecker, rows, openRows, toggleRowOpen, handleNestedSelect}) => {

    console.log(allItemsArray)
    const [session, setSession] = React.useState(demoSession);
    const authentication = React.useMemo(() => {
        return {
            signIn: () => {
                setSession(demoSession);
            },
            signOut: () => {
                setSession(null);
            },
        };
    }, []);


    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={rowStyle}>
                            <Checkbox

                                sx={checkBoxStyle}
                                // Checked if all nested orders are selected
                                checked={selectedItems.length == allItemsArray.length}
                                onChange={(event) => handleAllItemsSelect()}
                            />
                        </TableCell>

                        <TableCell sx={rowStyle} align={"center"}>Comprador</TableCell>
                        <TableCell sx={rowStyle}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignContent: 'center',
                                justifyContent: 'center',
                                textAlign: 'left'
                            }}>
                                Select
                                {/*<Switch sx={switchStyle} checked={switchChecker} onChange={(event) => {setSwitchChecker(prev => !prev)}} />*/}

                            </div>
                        </TableCell>

                        <TableCell sx={rowStyle}>Elemento</TableCell>
                        <TableCell sx={rowStyle} align="right">Quantity</TableCell>
                        <TableCell sx={rowStyle} align="right">Stock</TableCell>
                        <TableCell sx={rowStyle} align="right">Total</TableCell>
                        <TableCell sx={rowStyle} align="center">Enter Proveedor</TableCell>

                        <TableCell sx={rowStyle} align="center">Proveedor</TableCell>
                        <TableCell sx={rowStyle} align="center">Enter Price</TableCell>

                        <TableCell sx={rowStyle} align="right">Price</TableCell>
                        <TableCell sx={rowStyle} align="center">Information</TableCell>

                        {/*<TableCell align="right">Type</TableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fakeRows.map((item) => (
                            <ItemsProductionAccordionItem setSelectedItems={setSelectedItems} drivers={drivers} uniqueProveedors={uniqueProveedors} key={item._id} selectedItems={selectedItems} rowStyleMap={rowStyleMap} item={item} handleNestedSelect={handleNestedSelect} checkBoxStyle={checkBoxStyle} handleTopLevelSelect={handleTopLevelSelect} openRows={openRows} toggleRowOpen={toggleRowOpen}/>

                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ItemsProductionAccordion;