import React, { useEffect, useState } from 'react'
import './Dashboard.scss'
import PeopleInfo from './PeopleInfo/PeopleInfo'
import { mainApi } from '../../../../store/services/mainApi'
import { CircularProgress, Container, Button, Box } from '@mui/material'
import OrderInfo from './OrderInfo/OrderInfo'
import Progress from './Progress/Progress'
import TotalInfo from '../TotalInfo/TotalInfo'
import { useDispatch } from 'react-redux'
import { logAction } from '../../../../utils/logsAction/logService'
import CustomAlert from '../../../Rcomp/CustomAlert/CustomAlert'

export default function Dashboard({
  setFilterItemsValue,
  hideDashboard,
  editOrder,
  statuses,
  order,
  favoriteItems,
  progressItems,
  productionItems,
  shoppingItems,
}) {
  const { data: contacts, isLoading } = mainApi.useGetAllContactsQuery()
  const { data: ordersData } = mainApi.useGetAllOrdersQuery()
  const [contact, setContact] = useState(null)
  const dispatch = useDispatch()

  const [alertInfo, setAlertInfo] = useState('')
  const [openAlert, setOpenAlert] = useState(false)

  const usr = JSON.parse(localStorage.getItem('acc'))

  const { status } = order

  const updateTradeNameInOrderInfo = async () => {
    await fetch('https://tpomobi.shop/updateOrderTradeNameAdmin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          _id: order._id,
          tradeName: order?.userAcc?.tradeName ? order?.userAcc?.tradeName : '',
        },
      }),
    }).then((res) => {
      dispatch(mainApi.util.invalidateTags(['Order']))
    })
    setAlertInfo('Trade name successfully changed to' + ' ' + order?.userAcc?.tradeName)
    setOpenAlert(true)

    logAction('Changed trade name', {
      orderId: order._id,
      tradeName: order?.userAcc?.tradeName,
      oldTradeName: order?.tradeName,
      user: {
        ...usr,
        actions: {},
      },
    })
  }

  useEffect(() => {
    if (contacts && order) {
      console.log(order, 'order from dashboard')
      if (contacts.find((c) => c.phone === order?.phone || c.phone === order?.userAcc?.phone || c.phone === order?.msg?._data?.from.split('@')[0])) {
        setContact(
          contacts.find((c) => c.phone === order?.phone || c.phone === order?.userAcc?.phone || c.phone === order?.msg?._data?.from.split('@')[0]),
        )
      } else {
        setContact(order.userAcc)
      }
    }
  }, [order, contacts])

  useEffect(() => {
    if (contact) {
      console.log(contact, 'contact')
    } else {
      console.log('no contact')
    }
  }, [contact])

  return isLoading ? (
    <CircularProgress color='secondary' />
  ) : contact ? (
    <div className='dashboard'>
      <div className='dashboard__left'>
        {hideDashboard && <PeopleInfo ordersData={ordersData} contacts={contacts} order={order} contact={contact} />}
        <Box sx={{ display: 'flex', marginTop: '10px' }}>
          <Button
            size='small'
            sx={{ marginLeft: '10px' }}
            onClick={() => {
              window.open(`/client/static/order/${order?._id}`)
            }}
          >
            Client order link
          </Button>
          <Button
            size='small'
            // sx={{ marginBottom: '10px' }}
            onClick={() => {
              updateTradeNameInOrderInfo()
            }}
          >
            Change Trade Name
          </Button>
          {order?.realOrderID && (
            <Button
              size='small'
              // sx={{ marginBottom: '10px' }}
              onClick={() => {
                window.open(`${order?.realOrderID}`)
              }}
            >
              Original order link {order?.realOrderID && order?.realOrderID.slice(-6)}
            </Button>
          )}
        </Box>
        {hideDashboard && <OrderInfo editOrder={editOrder} order={order} />}
        <Progress
          progressItems={progressItems}
          productionItems={productionItems}
          shoppingItems={shoppingItems}
          order={order}
          favoriteItems={favoriteItems}
          setFilterItemsValue={setFilterItemsValue}
        />
      </div>
      <CustomAlert open={openAlert} setOpen={setOpenAlert} info={alertInfo} />
      <div className='dashboard__right'>{hideDashboard && <TotalInfo statuses={statuses} status={status} order={order} />}</div>
    </div>
  ) : (
    <Container>
      <h1>Cant find contact by phone {order?.phone || order?.userAcc?.phone || order?.msg?._data?.from.split('@')[0]}</h1>
    </Container>
  )
}
