import React, { useMemo, useEffect, useState } from 'react'
import '../../../../Table/TableHeader/TableHeader.scss'
import SearchIcon from '../../../../../assets/svg/Search.svg'
import {
  Link,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  TextField,
  useMediaQuery,
} from '@mui/material'
import { ReactComponent as Checklist } from '../../../../../assets/svg/CheckList.svg'
import { ReactComponent as Albaran } from '../../../../../assets/svg/Albaran.svg'
import { ReactComponent as ArrowDown } from '../../../../../assets/svg/ArrowDown.svg'
import { mainApi } from '../../../../../store/services/mainApi'
import { ThemeProvider } from '@emotion/react'
import theme from '../../../../../utils/themes/mainTheme'
import { useDispatch, useSelector } from 'react-redux'
import { changeSave } from '../../../../../store/slices/changeItems'
import { checkList } from '../../../../../utils/checkList'
import { albaran } from '../../../../../utils/albaran'

import { ReactComponent as Search } from '../../../../../assets/svg/Table/Search.svg'
import historySrc from '../../../../../assets/png/Table/history.png'
import filter from '../../../../../assets/png/Table/filter.png'
import printSrc from '../../../../../assets/png/Table/print.png'
import newOrder from '../../../../../assets/png/Table/add.png'
import AlbaranPrint from '../../../../PrintDocuments/Albaran/Albaran'
import AlbaranPrintVPF from '../../../../PrintDocuments/Albaran/AlbaranVPF'

import PDS from '../../../../PrintDocuments/Facturas/PDS'
import CheckList from '../../../../PrintDocuments/CheckList/CheckList'
import CheckListVPF from '../../../../PrintDocuments/CheckList/CheckListVPF'

import { PDFDownloadLink, saveAs, usePDF } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { logAction } from '../../../../../utils/logsAction/logService'
import { replaceAccent } from '../../../../../utils/accents'
import CustomAlert from '../../../../Rcomp/CustomAlert/CustomAlert'

export default function OrderChangeTableHeader({ order, setData, perPage, data, setSearchValue, searchValue, setPerPage, setEditOrder, editOrder }) {
  const mobileScreen = useMediaQuery('(max-width: 768px)')
  const usr = JSON.parse(localStorage.getItem('acc'))

  const [startEdittingOrder] = mainApi.useStartEditingOrderMutation()
  const [finishEdittingOrder] = mainApi.useFinishEditingOrderMutation()

  const searchHandler = (inputText) => {
    setSearchValue(inputText)
  }

  useEffect(() => {
    setPerPage(data.length)
  }, [data])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }

  //contact for Factura
  const { data: contacts } = mainApi.useGetAllContactsQuery()
  // const [contact, setContact] = useState({})

  // useEffect(() => {
  //   if (contacts && order) {
  //     console.log(order, 'order from dashboard')
  //     if (contacts.find((c) => c.phone === order?.phone || c.phone === order?.userAcc?.phone || c.phone === order?.msg?._data?.from.split('@')[0])) {
  //       setContact(
  //         contacts.find((c) => c.phone === order?.phone || c.phone === order?.userAcc?.phone || c.phone === order?.msg?._data?.from.split('@')[0]),
  //       )
  //     } else {
  //       try {
  //         setContact(contacts.find((c) => c.phone.includes(order?.userAcc?.phone)))
  //       } catch (error) {
  //         setContact(order.userAcc)
  //       }
  //     }
  //   }
  // }, [contacts])

  const contact = useMemo(() => {
    if (contacts && order) {
      if (contacts.find((c) => c.phone === order?.phone || c.phone === order?.userAcc?.phone || c.phone === order?.msg?._data?.from.split('@')[0])) {
        return contacts.find(
          (c) => c.phone === order?.phone || c.phone === order?.userAcc?.phone || c.phone === order?.msg?._data?.from.split('@')[0],
        )
      } else {
        return order.userAcc
      }
    }
  }, [contacts, order])

  //print Modal
  const [openPrintModal, setOpenPrintModal] = useState(false)

  const [openFactura, setOpenFactura] = useState(false)

  const { data: productsData, isLoading } = mainApi.useFindAllWordsQuery()
  const [changeItems] = mainApi.useChangeItemsInOrderMutation()
  const dispatch = useDispatch()

  const newItems = useSelector((state) => state.changeItems.items)

  const items = Array.isArray(order.items) ? order.items : []

  const [products, setProducts] = useState([])
  const [addProductModal, setAddProductModal] = useState(false)
  const [productSearch, setProductSearch] = useState('')
  const [selectedProducts, setSelectedProducts] = useState([])
  const [expandedCategories, setExpandedCategories] = useState([])

  //alert Open

  const [open, setOpen] = useState(false)
  const [info, setInfo] = useState('')

  // Albaran REACT-PDF

  // const itemsForAlbaran = useMemo(() => {
  //   if (data) {
  //     const uniqueItems = []
  //     data.forEach((item) => {
  //       const existingItem = uniqueItems.find((uniqueItem) => uniqueItem.sku === item.sku)
  //       if (existingItem) {
  //         existingItem.quantity += item.sku === 'your-condition' ? 1 : item.quantity
  //       } else {
  //         uniqueItems.push({
  //           conceptio: item.name,
  //           sku: item.sku,
  //           quantity: item.sku === 'your-condition' ? item.quantity + 1 : item.quantity,
  //           price: item.marketPriceCP,
  //           IVA: item.IVA ? item.IVA : 0,
  //           tipo: item.currentUnit ? item.currentUnit : '',
  //           total:
  //             item.marketPriceCP * (item.sku === 'your-condition' ? item.quantity + 1 : item.quantity)
  //               ? (item.marketPriceCP * (item.sku === 'your-condition' ? item.quantity + 1 : item.quantity)).toFixed(2)
  //               : 0,
  //         })
  //       }
  //     })
  //     return uniqueItems
  //   } else {
  //     return []
  //   }
  // }, [data])

  const itemsForAlbaran = useMemo(() => {
    if (data) {
      const uniqueItems = []
      data.forEach((item) => {
        const existingItemIndex = uniqueItems.findIndex((uniqueItem) => uniqueItem.sku === item.sku)
        if (existingItemIndex !== -1) {
          // Элемент с таким SKU уже существует
          uniqueItems[existingItemIndex].quantity += item.quantity
          uniqueItems[existingItemIndex].total = (uniqueItems[existingItemIndex].price * uniqueItems[existingItemIndex].quantity).toFixed(2)
        } else {
          // Элемент с таким SKU не найден, добавляем новый
          uniqueItems.push({
            conceptio: item.name,
            sku: item.sku,
            quantity: item.quantity,
            price: item.marketPriceCP,
            IVA: item.IVA ? item.IVA : 0,
            tipo: item.currentUnit ? item.currentUnit : '',
            total: (item.marketPriceCP * item.quantity).toFixed(2),
          })
        }
      })
      return uniqueItems
    } else {
      return []
    }
  }, [data])

  const itemsForChecklist = useMemo(() => {
    if (data) {
      const uniqueItems = []
      data.forEach((item) => {
        const existingItem = uniqueItems.find((uniqueItem) => uniqueItem.sku === item.sku)
        if (existingItem) {
          existingItem.quantity += item.sku === 'your-condition' ? 1 : item.quantity
        } else {
          uniqueItems.push({
            conceptio: item.name,
            sku: item.sku,
            quantity: item.sku === 'your-condition' ? item.quantity + 1 : item.quantity,
            price: item.marketPriceCP,
            IVA: item.IVA ? item.IVA : 0,
            tipo: item.currentUnit ? item.currentUnit : '',
            total:
              item.marketPriceCP * (item.sku === 'your-condition' ? item.quantity + 1 : item.quantity)
                ? (item.marketPriceCP * (item.sku === 'your-condition' ? item.quantity + 1 : item.quantity)).toFixed(2)
                : 0,
            status: item?.status ? item.status : {},
          })
        }
      })
      return uniqueItems
    } else {
      return []
    }
  }, [data])

  const [instance, update] = usePDF({
    document: <AlbaranPrint items={itemsForAlbaran} order={order} contact={contact} />,
  })

  //start editting

  const changeEditting = () => {
    if (!editOrder) {
      startEdittingOrder({ orderId: order._id, userId: usr._id })
        .unwrap()
        .then(() => {
          setEditOrder(true)
        })
        .catch((error) => {
          console.log(error)
          setInfo(error.data.user?.name + ' ' + error.data.user?.phone + ' ' + error.data.user?.email)
          setOpen(true)
        })
    } else {
      finishEdittingOrder({ orderId: order._id, userId: usr._id })
        .unwrap()
        .then(() => {
          setEditOrder(false)
        })
        .catch((error) => {
          alert(error)
        })
    }
  }

  //util for stoop edit

  const autoStopEditting = (pass) => {
    if (pass == '355') {
      finishEdittingOrder({ orderId: order._id, userId: usr._id })
    }
  }

  // useEffect(() => {
  //   if (editOrder) startEdittingOrder({ orderId: order._id, userId: usr._id })
  //   else finishEdittingOrder({ orderId: order._id, userId: usr._id })
  // }, [editOrder])

  useEffect(() => {
    if (localStorage.getItem('name') == 'VPF') {
      update(<AlbaranPrintVPF items={itemsForAlbaran} order={order} contact={contact} />)
    } else {
      update(<AlbaranPrint items={itemsForAlbaran} order={order} contact={contact} />)
    }
    console.log('updateAlbaran')
  }, [itemsForAlbaran, order, contact])

  // PDS FACTURA

  // const [facturaDate, setFacturaDate] = useState(dayjs())
  // const [facturaNumber, setFacturaNumber] = useState('001')
  // const [facturaNumberInYear, setFacturaNumberInYear] = useState('001')

  // const [instancePDS, updatePDS] = usePDF({
  //   document: (
  //     <PDS
  //       items={itemsForAlbaran}
  //       order={order}
  //       contact={contact}
  //       name={'Sergey Fedorenko'}
  //       value={'X7031676R'}
  //       adress={'JOSEP PLA 29 1 - 1, Barcelona, 08019'}
  //       date={facturaDate}
  //       number={facturaNumber}
  //       numberInYear={facturaNumberInYear}
  //       IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
  //     />
  //   ),
  // })

  // const [instanceACC, updateACC] = usePDF({
  //   document: (
  //     <PDS
  //       items={itemsForAlbaran}
  //       order={order}
  //       contact={contact}
  //       name={'Claudia Cires'}
  //       value={'Y4155866X'}
  //       adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
  //       date={facturaDate}
  //       number={facturaNumber}
  //       numberInYear={facturaNumberInYear}
  //       IBAN={'IBAN ES70 0081 0168 9300 0178 7989'}
  //     />
  //   ),
  // })
  // const [instanceMSL, updateMSL] = usePDF({
  //   document: (
  //     <PDS
  //       items={itemsForAlbaran}
  //       order={order}
  //       contact={contact}
  //       name={'Moldovenesque SL'}
  //       value={'B67505446'}
  //       adress={'Rambla de Prim 166 local 3, Barcelona, 08020'}
  //       date={facturaDate}
  //       number={facturaNumber}
  //       numberInYear={facturaNumberInYear}
  //       IBAN={'IBAN ES56 0081 0126 0000 0164 3675'}
  //     />
  //   ),
  // })

  // useEffect(() => {
  //   updatePDS(
  //     <PDS
  //       items={itemsForAlbaran}
  //       order={order}
  //       contact={contact}
  //       name={'Sergey Fedorenko'}
  //       value={'X7031676R'}
  //       adress={'JOSEP PLA 29 1 - 1, Barcelona, 08019'}
  //       date={facturaDate}
  //       number={facturaNumber}
  //       numberInYear={facturaNumberInYear}
  //       IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
  //     />,
  //   )
  //   updateACC(
  //     <PDS
  //       items={itemsForAlbaran}
  //       order={order}
  //       contact={contact}
  //       name={'Claudia Cires'}
  //       value={'Y4155866X'}
  //       adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
  //       date={facturaDate}
  //       number={facturaNumber}
  //       numberInYear={facturaNumberInYear}
  //       IBAN={'IBAN ES70 0081 0168 9300 0178 7989'}
  //     />,
  //   )
  //   updateMSL(
  //     <PDS
  //       items={itemsForAlbaran}
  //       order={order}
  //       contact={contact}
  //       name={'Moldovenesque SL'}
  //       value={'B67505446'}
  //       adress={'Rambla de Prim 166 local 3, Barcelona, 08020'}
  //       date={facturaDate}
  //       number={facturaNumber}
  //       numberInYear={facturaNumberInYear}
  //       IBAN={'IBAN ES56 0081 0126 0000 0164 3675'}
  //     />,
  //   )
  // }, [itemsForAlbaran, order, contact, facturaDate, facturaNumber, facturaNumberInYear])

  // Check List

  const [instanceCheckList, updateCheckList] = usePDF({
    document: <CheckList items={itemsForChecklist} order={order} contact={contact} />,
  })

  useEffect(() => {
    if (localStorage.getItem('name') == 'VPF') {
      updateCheckList(<CheckListVPF items={itemsForChecklist} order={order} contact={contact} />)
    } else {
      updateCheckList(<CheckList items={itemsForChecklist} order={order} contact={contact} />)
    }
  }, [itemsForChecklist, order, contact])

  //add products filter
  const [searchAddProd, setSearchAddProd] = useState('')

  const filtredProductsAdd = useMemo(() => {
    // Normalize the search value
    const normalizedSearchAddProd = replaceAccent(searchAddProd).toLowerCase()

    return products.filter((elem) => {
      // Normalize the product name
      const normalizedProductName = replaceAccent(elem.name).toLowerCase()

      // Perform the accent-insensitive and case-insensitive search
      if (normalizedSearchAddProd === '') {
        return true
      } else {
        return normalizedProductName.includes(normalizedSearchAddProd)
      }
    })
  }, [searchAddProd, products])

  //itemsUpdateModal
  const [showModal, setShowModal] = useState(false)

  const addSelectedProduct = (product) => {
    if (!selectedProducts.find((elem) => elem._id === product._id)) {
      setSelectedProducts((prev) => [...prev, { ...product, quantity: 1, quantityOrder: 1 }])
    } else {
      setSelectedProducts((prev) => prev.filter((elem) => elem._id !== product._id))
    }
  }

  const incrementQuantity = (productId) => {
    setSelectedProducts((prevProducts) => {
      return prevProducts.map((product) => {
        if (product._id === productId) {
          return { ...product, quantity: (product.quantity || 0) + 1 }
        }
        return product
      })
    })
  }

  const decrementQuantity = (productId) => {
    setSelectedProducts((prevProducts) => {
      return prevProducts.map((product) => {
        if (product._id === productId) {
          return { ...product, quantity: (product.quantity || 1) === 1 ? 1 : (product.quantity || 1) - 1 }
        }
        return product
      })
    })
  }

  useEffect(() => {
    if (productsData) {
      setProducts(productsData)
    }
  }, [productsData])

  // useEffect(() => {
  //   console.log(order, 'order')
  // }, [order])

  useEffect(() => {
    console.log(selectedProducts)
  }, [selectedProducts])

  const filtredProducts = useMemo(() => {
    return products.filter((elem) => {
      //by name
      if (productSearch === '') {
        return elem
      } else {
        return elem.name.toLowerCase().includes(productSearch.toLowerCase())
      }
    })
  }, [productSearch, products])

  const uniqueCategories = useMemo(() => {
    let categoryArray = products.map((product) => product.category)
    categoryArray = categoryArray.flatMap((category) => {
      if (category === null || category === undefined || category === '') {
        return []
      }
      if (category.includes(',')) {
        return category.split(',').map((c) => c.trim())
      }
      return category
    })
    const uniqueSet = new Set(categoryArray)
    const uniqueCategoriesArray = Array.from(uniqueSet)
    uniqueCategoriesArray.sort()
    return uniqueCategoriesArray.map((category) => ({
      label: category,
      value: category,
    }))
  }, [products])

  const handleClose = () => {
    setAddProductModal(false)
  }

  //itemsUpdateModal
  const handleCloseModal = () => {
    setShowModal(false)
  }
  const handleOpenModal = () => {
    dispatch(changeSave(true))
    setShowModal(true)
  }

  // Utility function to calculate the differences between two arrays of items
  const calculateDifferences = (oldItems, newItems) => {
    const oldMap = new Map(oldItems.map((item) => [item.id, item]))
    const newMap = new Map(newItems.map((item) => [item.id, item]))

    const added = newItems.filter((item) => !oldMap.has(item.id))
    const removed = oldItems.filter((item) => !newMap.has(item.id))

    const modified = newItems
      .filter((item) => {
        const oldItem = oldMap.get(item.id)
        return oldItem && JSON.stringify(oldItem) !== JSON.stringify(item)
      })
      .map((item) => {
        const oldItem = oldMap.get(item.id)
        const changes = {}

        for (const key in item) {
          if (item[key] !== oldItem[key]) {
            changes[key] = { old: oldItem[key], new: item[key] }
          }
        }

        return { id: item._id, sku: item.sku, changes }
      })

    return { added, removed, modified }
  }

  // Example usage
  const logItemChanges = async (order, newItems, usr) => {
    const { added, removed, modified } = calculateDifferences(order.items, newItems)

    const changeDetails = {
      orderId: order._id,
      changes: {
        added: added.map((item) => ({ id: item._id, name: item.name })),
        removed: removed.map((item) => ({ id: item._id, name: item.name })),
        modified,
      },
      user: {
        ...usr,
        actions: {},
      },
    }

    await logAction('Change items', changeDetails)
  }

  const agree = () => {
    changeItems({
      elem: {
        ...order,
        items: [...newItems],
      },
    })
      .unwrap()
      .then(() => {
        setShowModal(false)

        dispatch(changeSave(false))
        dispatch(mainApi.util.invalidateTags(['Order']))

        finishEdittingOrder({ orderId: order._id, userId: usr._id })
          .unwrap()
          .then(() => {
            setEditOrder(false)
            dispatch(mainApi.util.invalidateTags(['Order']))
          })
          .catch((error) => {
            console.error('Error:', error)
          })

        // TYT 9I JOPY m9JI

        const info = {
          data: {
            items: [...newItems],
            orderID: order._id,
            order: order,
          },
        }
        console.log(info)

        fetch('https://tpomobi.shop/mondayChangeItemZumos', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(info),
        }).catch((error) => {
          console.error('Error:', error)
        })

        console.log('JPY')
        fetch('https://tpomobi.shop/sendOrderChangeInfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data: { order: { ...order, items: [...newItems] } } }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log('Success:', data)
          })
          .catch((error) => {
            console.error('Error:', error)
          })
      })

    logItemChanges(order, newItems, usr)
  }

  const cancel = () => {
    setShowModal(false)
    dispatch(changeSave(false))
  }

  const changeItemsinOrder = async () => {
    const newSelectedItems = selectedProducts.map((elem) => ({
      ...elem,
      // quantity: 1,
      quantityOrder: elem.quantity,
    }))
    const body = {
      elem: {
        ...order,
        items: [...items, ...newSelectedItems],
      },
    }
    await changeItems(body)
    dispatch(mainApi.util.invalidateTags(['Order']))
    setSelectedProducts([])

    console.log('ETO KAK BU TyT')

    const info = {
      data: { ...order, items: [...newSelectedItems] },
    }
    console.log(info)
    fetch('https://tpomobi.shop/mondayAddItemZumos', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(info),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data)
      })
      .catch((error) => {
        console.error('Error:', error)
      })

    ///tyt
  }

  const handleCategoryToggle = (category) => {
    if (expandedCategories.includes(category)) {
      setExpandedCategories(expandedCategories.filter((c) => c !== category))
    } else {
      setExpandedCategories([...expandedCategories, category])
    }
  }

  const groups = useMemo(() => {
    const groupedProducts = {}
    filtredProducts.forEach((product) => {
      if (!groupedProducts[product.category]) {
        groupedProducts[product.category] = []
      }
      groupedProducts[product.category].push(product)
    })
    return groupedProducts
  }, [filtredProducts])

  const handleChangePerpage = (event) => {
    if (data.length > event.target.value) {
      setPerPage(event.target.value)
    } else {
      setPerPage(data.length)
    }
  }
  return (
    <div className='tableHeader'>
      <div className='tableHeader__title'>
        Showing 1-{perPage} of {data.length} items.
      </div>

      {!mobileScreen && (
        <div className='tableHeader__body'>
          <ThemeProvider theme={theme}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel sx={{ fontFamily: 'Inter', color: '#06c0b0', '&.Mui-focused': { color: '#06c0b0' } }}>Rows Quantity</InputLabel>
              <Select
                onChange={(e) => handleChangePerpage(e)}
                label='Rows Quantity'
                className='select'
                renderValue={() => perPage}
                value={perPage}
                size='small'
              >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={150}>150</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </FormControl>
          </ThemeProvider>

          {/* button for filters for contacts */}
          <div className='tableHeader__body__btn'>Filters</div>

          <div className='tableHeader__body__search '>
            <input type='text' placeholder='Search' onChange={(e) => searchHandler(e.target.value)} value={searchValue} />
            <span>
              <img src={SearchIcon} alt='' />
            </span>
          </div>
          {editOrder && (
            <div className='tableHeader__body__btn active rightSide' onClick={handleOpenModal}>
              Save
            </div>
          )}
          <Modal open={showModal} onClose={handleCloseModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                borderRadius: '10px',
                boxShadow: 24,
                p: 4,
                display: 'flex',
                flexDirection: 'column',

                alignItems: 'center',
              }}
            >
              <Typography sx={{ mb: 2 }} id='modal-modal-title' variant='h6' component='h2'>
                Are you sure?
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button onClick={agree} variant='contained' color='success'>
                  Agree
                </Button>
                <Button onClick={cancel} variant='contained' color='error'>
                  Disagree
                </Button>
              </Box>
            </Box>
          </Modal>
          <div
            className='tableHeader__body__btn active rightSide'
            onClick={() => {
              changeEditting()
              // setEditOrder((prev) => !prev)
            }}
          >
            {editOrder ? 'Back' : 'Edit'}
          </div>
          <button
            disabled={isLoading}
            style={{
              height: '42px',
              margin: '8px',
            }}
            className='tableHeader__body__btn active rightSide'
            onClick={() => {
              setAddProductModal(true)
            }}
          >
            Add Product
          </button>
          <button
            disabled={isLoading}
            style={{
              height: '42px',
              position: 'relative',
              backgroundColor: openPrintModal ? '#506e51' : '',
            }}
            className='tableHeader__body__btn active rightSide'
            onClick={() => {
              setOpenPrintModal((prev) => !prev)
            }}
          >
            Print
            <div
              style={{
                width: '150px',
                display: `${openPrintModal ? 'flex' : 'none'}`,
                flexDirection: 'column',
                position: 'absolute',
                right: '0',
                top: '40px',
                zIndex: '10',
                borderRadius: '12px',
                background: '#2D2D2D',
                padding: '10px',
                gap: '10px',
              }}
            >
              {contact ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Checklist width={20} height={20} style={{ marginRight: '10px' }} />
                  {instanceCheckList.loading ? (
                    <CircularProgress color='success' />
                  ) : !instanceCheckList.error ? (
                    <a
                      style={{ textDecoration: 'none', color: 'white' }}
                      href={instanceCheckList.url}
                      download={order.idTucomida ? `CHECKLIST-${order.idTucomida}.pdf` : `CHECKLIST-${order._id.slice(0, 6)}.pdf`}
                      // target='_blank'
                      // rel='noreferrer'
                    >
                      CHECK LIST
                    </a>
                  ) : (
                    <div>Err</div>
                  )}
                </div>
              ) : (
                '...'
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Albaran width={20} height={20} style={{ marginRight: '10px' }} />{' '}
                {instance.loading ? (
                  <CircularProgress color='success' />
                ) : !instance.error ? (
                  <a
                    style={{ textDecoration: 'none', color: 'white' }}
                    href={instance.url}
                    download={order.idTucomida ? `ALBARAN-${order.idTucomida}.pdf` : `ALBARAN-${order._id.slice(-6)}.pdf`}
                  >
                    ALBARAN
                  </a>
                ) : (
                  <div>Err</div>
                )}
              </div>
              {/* <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                onClick={(e) => {
                  setOpenFactura((prev) => !prev)
                  e.stopPropagation()
                  e.preventDefault()
                }}
              >
                <ArrowDown style={{ transition: 'all 0.3s ease' }} rotate={openFactura ? 180 : 0} fill='#FFFFFF' width={20} height={20} /> FACTURA
              </div> */}
              {/* <div
                onClick={(e) => {
                  // e.stopPropagation()
                  // e.preventDefault()
                  setOpenFactura((prev) => !prev)
                }}
                style={{ display: openFactura ? 'flex' : 'none', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#FFF',
                    borderRadius: '2px',
                    padding: '2px',
                    color: '#407BFF',
                  }}
                >
                  {instancePDS.loading ? (
                    <CircularProgress color='success' />
                  ) : !instancePDS.error ? (
                    <a
                      style={{ textDecoration: 'none', color: '#407BFF' }}
                      href={instancePDS.url}
                      download={order.idTucomida ? `FACTURA-ASF-${order.idTucomida}.pdf` : `FACTURA-ASF-${order._id}.pdf`}
                      // target='_blank'
                      // rel='noreferrer'
                    >
                      ASF
                    </a>
                  ) : (
                    <div>Err</div>
                  )}
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#FFF',
                    borderRadius: '2px',
                    padding: '2px',
                    color: '#407BFF',
                  }}
                >
                  {instanceACC.loading ? (
                    <CircularProgress color='success' />
                  ) : !instanceACC.error ? (
                    <a
                      style={{ textDecoration: 'none', color: '#407BFF' }}
                      href={instanceACC.url}
                      download={order.idTucomida ? `FACTURA-ACC-${order.idTucomida}.pdf` : `FACTURA-ACC-${order._id}.pdf`}
                      // target='_blank'
                      // rel='noreferrer'
                    >
                      ACC
                    </a>
                  ) : (
                    <div>Err</div>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#FFF',
                    borderRadius: '2px',
                    padding: '2px',
                    color: '#216A22',
                  }}
                >
                  {instanceMSL.loading ? (
                    <CircularProgress color='success' />
                  ) : !instanceMSL.error ? (
                    <a
                      style={{ textDecoration: 'none', color: '#407BFF' }}
                      href={instanceMSL.url}
                      download={order.idTucomida ? `FACTURA-MSL-${order.idTucomida}.pdf` : `FACTURA-MSL-${order._id}.pdf`}
                      // target='_blank'
                      // rel='noreferrer'
                    >
                      MSL
                    </a>
                  ) : (
                    <div>Err</div>
                  )}
                </div>
              </div> */}
              {/* <Modal
                // sx={{
                //   '& .MuiBackdrop-root': {
                //     backgroundColor: 'transparent',
                //   },
                // }}
                open={openFactura}
                onClose={(e) => {
                  e.stopPropagation()
                  setOpenFactura(false)
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 300,
                    height: 400,
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    position: 'absolute',
                    // bottom: '100px',
                    // left: '35%',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '10px',
                  }}
                >
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <DatePicker label='Date' value={facturaDate} onChange={(newValue) => setFacturaDate(newValue)} />
                  </FormControl>
                  <TextField sx={{ width: 250 }} label='Number this year' value={facturaNumber} onChange={(e) => setFacturaNumber(e.target.value)} />
                  <TextField
                    sx={{ width: 250 }}
                    label='Number in year'
                    value={facturaNumberInYear}
                    onChange={(e) => setFacturaNumberInYear(e.target.value)}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                    <Button
                      variant='outlined'
                      sx={{
                        padding: '0px',
                      }}
                    >
                      <Link
                        href={instancePDS.url}
                        download={`FACTURA-ASF-${order?.tradeName}.pdf` || `FACTURA-ASF-${order?.name}.pdf`}
                        // target='_blank'
                        // rel='noreferrer'
                        style={{
                          color: '#407BFF',
                          fontWeight: 'bold',
                          fontFamily: 'Archivo Black',
                          textDecoration: 'none',
                          underline: 'none',
                          cursor: 'pointer',
                          padding: '5px',
                          width: '100%',
                        }}
                      >
                        ASF
                      </Link>
                    </Button>
                    <Button
                      sx={{
                        padding: '0px',
                      }}
                      variant='outlined'
                    >
                      <Link
                        href={instanceACC.url}
                        download={`FACTURA-ACC-${order?.tradeName}.pdf` || `FACTURA-ACC-${order?.name}.pdf`}
                        style={{
                          color: '#407BFF',
                          fontWeight: 'bold',
                          fontFamily: 'Archivo Black',
                          textDecoration: 'none',
                          underline: 'none',
                          cursor: 'pointer',
                          padding: '5px',
                          width: '100%',
                        }}
                      >
                        ACC
                      </Link>
                    </Button>
                    <Button
                      sx={{
                        padding: '0px',
                      }}
                      variant='outlined'
                    >
                      <Link
                        href={instanceMSL.url}
                        download={`FACTURA-MSL-${order?.tradeName}.pdf` || `FACTURA-MSL-${order?.name}.pdf`}
                        style={{
                          color: '#407BFF',
                          fontWeight: 'bold',
                          fontFamily: 'Archivo Black',
                          textDecoration: 'none',
                          underline: 'none',
                          cursor: 'pointer',
                          padding: '5px',
                          width: '100%',
                        }}
                      >
                        MSL
                      </Link>
                    </Button>
                  </Box>
                </Box>
              </Modal> */}
            </div>
          </button>
          <div style={{ position: 'absolute', right: '10%', display: `${addProductModal ? 'flex' : 'none'}` }} className='AddProduct'>
            {isLoading ? (
              <CircularProgress color='success' />
            ) : (
              <Modal open={addProductModal} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
                <Box sx={style}>
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <TextField label='Search' value={searchAddProd} onChange={(e) => setSearchAddProd(e.target.value)} fullWidth></TextField>
                    <div
                      onClick={() => {
                        setSearchAddProd('')
                      }}
                      style={{
                        position: 'absolute',
                        right: '5%',
                        cursor: 'pointer',
                        fontSize: '20px',
                        fontWeight: 'lighter',
                        marginTop: '10px',
                        border: '1px solid #000000',
                        padding: '5px',
                        borderRadius: '5px',
                      }}
                    >
                      х
                    </div>
                  </Box>

                  {searchAddProd ? (
                    <Box
                      sx={{
                        // p: 2,
                        height: '300px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        overflowY: 'scroll',
                        width: '100%',
                      }}
                    >
                      {filtredProductsAdd.map((product, index) => {
                        if (index < 25) {
                          return (
                            <MenuItem
                              sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
                              key={product.id}
                              value={product}
                              onClick={() => addSelectedProduct(product)}
                            >
                              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <input
                                  className='checkbox'
                                  type='checkbox'
                                  style={{ cursor: 'pointer' }}
                                  key={product.id}
                                  checked={selectedProducts.find((p) => p._id === product._id) ? true : false}
                                />
                                <img width={50} height={50} src={product.img} alt={product.name} />
                              </div>
                              <div>{product.name ? product.name : ''}</div>
                              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <p>{product.marketPriceCP ? product.marketPriceCP.toFixed(2) : 'not set'} €</p>
                                {selectedProducts.find((p) => p._id === product._id) && (
                                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <Button
                                      variant='outlined'
                                      size='small'
                                      sx={{
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        border: '1px solid #000',
                                        color: '#000',
                                        // padding: '0px',
                                        width: 'max-content',
                                        // borderRadius: '5px',
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        incrementQuantity(product._id)
                                      }}
                                    >
                                      +1
                                    </Button>
                                    <p>{selectedProducts.find((p) => p._id === product._id)?.quantity}</p>
                                    <Button
                                      variant='outlined'
                                      size='small'
                                      sx={{
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        border: '1px solid #000',
                                        color: '#000',
                                        // padding: '0px',
                                        width: 'max-content',
                                        // borderRadius: '5px',
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        decrementQuantity(product._id)
                                      }}
                                    >
                                      -1
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </MenuItem>
                          )
                        }
                      })}
                    </Box>
                  ) : (
                    <Box
                      sx={{ p: 2, height: '300px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', overflowY: 'scroll' }}
                    >
                      {uniqueCategories.map((category) => (
                        <div key={category.value}>
                          <div
                            style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
                            onClick={() => handleCategoryToggle(category.value)}
                          >
                            <p>{category.label}</p>
                            <p>{expandedCategories.includes(category.value) ? '-' : '+'}</p>
                          </div>
                          {expandedCategories.includes(category.value) && (
                            <div>
                              {groups[category.value]
                                .sort((a, b) => a.name.localeCompare(b.name))
                                ?.map((product, index) => (
                                  <div style={{ display: 'flex', justifyContent: 'space-between' }} key={product._id}>
                                    <input
                                      style={{ alignSelf: 'center' }}
                                      checked={selectedProducts.find((p) => p._id === product._id) ? true : false}
                                      className='checkbox'
                                      type='checkbox'
                                      onChange={() => addSelectedProduct(product)}
                                    />
                                    <div style={{ display: 'flex', alignItems: 'center', width: '90%', justifyContent: 'space-between' }}>
                                      <img width={50} height={50} src={product.img} alt={product.name} />
                                      <p>{product.name}</p>
                                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        <p>{product.marketPriceCP ? product.marketPriceCP.toFixed(2) : 'not set'} €</p>
                                        {selectedProducts.find((p) => p._id === product._id) && (
                                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <Button
                                              variant='outlined'
                                              size='small'
                                              sx={{
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                                border: '1px solid #000',
                                                color: '#000',
                                                // padding: '0px',
                                                width: 'max-content',
                                                // borderRadius: '5px',
                                              }}
                                              onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                incrementQuantity(product._id)
                                              }}
                                            >
                                              +1
                                            </Button>
                                            <p>{selectedProducts.find((p) => p._id === product._id)?.quantity}</p>
                                            <Button
                                              variant='outlined'
                                              size='small'
                                              sx={{
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                                border: '1px solid #000',
                                                color: '#000',
                                                // padding: '0px',
                                                width: 'max-content',
                                                // borderRadius: '5px',
                                              }}
                                              onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                decrementQuantity(product._id)
                                              }}
                                            >
                                              -1
                                            </Button>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      ))}
                      {/* {filtredProducts?.map((product, index) => (
                    
                  ))} */}
                    </Box>
                  )}

                  <Button
                    variant='contained'
                    size='small'
                    style={{ width: '100%' }}
                    onClick={() => {
                      changeItemsinOrder()
                      setAddProductModal(false)
                    }}
                  >
                    Add
                  </Button>
                </Box>
              </Modal>
            )}
          </div>
        </div>
      )}
      {mobileScreen && (
        <div className='tableHeader__body' style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          {editOrder && (
            <div style={{ cursor: 'pointer', width: '50px' }} className='tableHeader__body__btn active rightSide' onClick={handleOpenModal}>
              Save
            </div>
          )}
          <Modal open={showModal} onClose={handleCloseModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                borderRadius: '10px',
                boxShadow: 24,
                p: 4,
                display: 'flex',
                flexDirection: 'column',

                alignItems: 'center',
              }}
            >
              <Typography sx={{ mb: 2 }} id='modal-modal-title' variant='h6' component='h2'>
                Are you sure?
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button onClick={agree} variant='contained' color='success'>
                  Agree
                </Button>
                <Button onClick={cancel} variant='contained' color='error'>
                  Disagree
                </Button>
              </Box>
            </Box>
          </Modal>
          <div
            style={{ cursor: 'pointer', width: '50px' }}
            className='tableHeader__body__btn active rightSide'
            onClick={() => {
              changeEditting()
              // setEditOrder((prev) => !prev)
            }}
          >
            {editOrder ? 'Back' : 'Edit'}
          </div>
          <div className='tableHeader__body__search '>
            <input type='text' placeholder='Search' onChange={(e) => searchHandler(e.target.value)} value={searchValue} />
            <span>
              <img src={SearchIcon} alt='' />
            </span>
          </div>

          {/* <img style={{ cursor: 'pointer' }} src={historySrc} alt=' history' />
          <img style={{ cursor: 'pointer' }} src={filter} alt='filter' /> */}
          {/* start new order modal */}
          <button
            disabled={isLoading}
            style={{
              height: '42px',
              width: '42px',
              background: 'transparent',
              position: 'relative',
            }}
            className='tableHeader__body__btn active rightSide'
            onClick={() => {
              setOpenPrintModal((prev) => !prev)
            }}
          >
            <img src={printSrc} alt='print' />
            <div
              style={{
                width: '150px',
                display: `${openPrintModal ? 'flex' : 'none'}`,
                flexDirection: 'column',
                position: 'absolute',
                right: '0',
                top: '40px',
                zIndex: '10',
                borderRadius: '12px',
                background: '#2D2D2D',
                padding: '10px',
                gap: '10px',
              }}
            >
              {contact ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    border: '1px solid white',
                    borderRadius: '5px',
                  }}
                >
                  <Checklist width={20} height={20} style={{ marginRight: '10px' }} />
                  {instanceCheckList.loading ? (
                    <CircularProgress color='success' />
                  ) : !instanceCheckList.error ? (
                    <a
                      style={{ textDecoration: 'none', color: 'white' }}
                      href={instanceCheckList.url}
                      download={order.idTucomida ? `CHECKLIST-${order.idTucomida}.pdf` : `CHECKLIST-${order._id.slice(-6)}.pdf`}
                      // target='_blank'
                      // rel='noreferrer'
                    >
                      CHECK LIST
                    </a>
                  ) : (
                    <div>Err</div>
                  )}
                </div>
              ) : (
                '...'
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Albaran width={20} height={20} style={{ marginRight: '10px' }} />
                {instance.loading ? (
                  <CircularProgress color='success' />
                ) : !instance.error ? (
                  <a
                    style={{
                      textDecoration: 'none',
                      color: 'white',
                    }}
                    href={instance.url}
                    download={order.idTucomida ? `ALBARAN-${order.idTucomida}.pdf` : `ALBARAN-${order._id.slice(-6)}.pdf`}
                  >
                    ALBARAN
                  </a>
                ) : (
                  <div>Err</div>
                )}
              </div>
              {/* <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                onClick={(e) => {
                  setOpenFactura((prev) => !prev)
                  e.stopPropagation()
                  e.preventDefault()
                }}
              >
                <ArrowDown style={{ transition: 'all 0.3s ease' }} rotate={openFactura ? 180 : 0} fill='#FFFFFF' width={20} height={20} /> FACTURA
              </div> */}
              <div
                onClick={(e) => {
                  // e.stopPropagation()
                  // e.preventDefault()
                  setOpenFactura((prev) => !prev)
                }}
                style={{ display: openFactura ? 'flex' : 'none', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}
              >
                {/* <Modal
                  // sx={{
                  //   '& .MuiBackdrop-root': {
                  //     backgroundColor: 'transparent',
                  //   },
                  // }}
                  open={openFactura}
                  onClose={(e) => {
                    e.stopPropagation()
                    setOpenFactura(false)
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 300,
                      height: 400,
                      backgroundColor: 'white',
                      borderRadius: '10px',
                      position: 'absolute',
                      // bottom: '100px',
                      // left: '35%',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      padding: '10px',
                    }}
                  >
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <DatePicker label='Date' value={facturaDate} onChange={(newValue) => setFacturaDate(newValue)} />
                    </FormControl>
                    <TextField
                      sx={{ width: 250 }}
                      label='Number this year'
                      value={facturaNumber}
                      onChange={(e) => setFacturaNumber(e.target.value)}
                    />
                    <TextField
                      sx={{ width: 250 }}
                      label='Number in year'
                      value={facturaNumberInYear}
                      onChange={(e) => setFacturaNumberInYear(e.target.value)}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                      <Button
                        variant='outlined'
                        sx={{
                          padding: '0px',
                        }}
                      >
                        <Link
                          href={instancePDS.url}
                          download={`FACTURA-ASF-${order?.tradeName}.pdf` || `FACTURA-ASF-${order?.name}.pdf`}
                          // target='_blank'
                          // rel='noreferrer'
                          style={{
                            color: '#407BFF',
                            fontWeight: 'bold',
                            fontFamily: 'Archivo Black',
                            textDecoration: 'none',
                            underline: 'none',
                            cursor: 'pointer',
                            padding: '5px',
                            width: '100%',
                          }}
                        >
                          ASF
                        </Link>
                      </Button>
                      <Button
                        sx={{
                          padding: '0px',
                        }}
                        variant='outlined'
                      >
                        <Link
                          href={instanceACC.url}
                          download={`FACTURA-ACC-${order?.tradeName}.pdf` || `FACTURA-ACC-${order?.name}.pdf`}
                          style={{
                            color: '#407BFF',
                            fontWeight: 'bold',
                            fontFamily: 'Archivo Black',
                            textDecoration: 'none',
                            underline: 'none',
                            cursor: 'pointer',
                            padding: '5px',
                            width: '100%',
                          }}
                        >
                          ACC
                        </Link>
                      </Button>
                      <Button
                        sx={{
                          padding: '0px',
                        }}
                        variant='outlined'
                      >
                        <Link
                          href={instanceMSL.url}
                          download={`FACTURA-MSL-${order?.tradeName}.pdf` || `FACTURA-MSL-${order?.name}.pdf`}
                          style={{
                            color: '#407BFF',
                            fontWeight: 'bold',
                            fontFamily: 'Archivo Black',
                            textDecoration: 'none',
                            underline: 'none',
                            cursor: 'pointer',
                            padding: '5px',
                            width: '100%',
                          }}
                        >
                          MSL
                        </Link>
                      </Button>
                    </Box>
                  </Box>
                </Modal> */}
                {/* <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#FFF',
                    borderRadius: '2px',
                    padding: '2px',
                    color: '#407BFF',
                  }}
                >
                  {instancePDS.loading ? (
                    <CircularProgress color='success' />
                  ) : !instancePDS.error ? (
                    <a
                      style={{ textDecoration: 'none', color: '#407BFF' }}
                      href={instancePDS.url}
                      download={order.idTucomida ? `FACTURA-ASF-${order.idTucomida}.pdf` : `FACTURA-ASF-${order._id}.pdf`}
                      // target='_blank'
                      // rel='noreferrer'
                    >
                      ASF
                    </a>
                  ) : (
                    <div>Err</div>
                  )}
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#FFF',
                    borderRadius: '2px',
                    padding: '2px',
                    color: '#407BFF',
                  }}
                >
                  {instanceACC.loading ? (
                    <CircularProgress color='success' />
                  ) : !instanceACC.error ? (
                    <a
                      style={{ textDecoration: 'none', color: '#407BFF' }}
                      href={instanceACC.url}
                      download={order.idTucomida ? `FACTURA-ACC-${order.idTucomida}.pdf` : `FACTURA-ACC-${order._id}.pdf`}
                      // target='_blank'
                      // rel='noreferrer'
                    >
                      ACC
                    </a>
                  ) : (
                    <div>Err</div>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#FFF',
                    borderRadius: '2px',
                    padding: '2px',
                    color: '#216A22',
                  }}
                >
                  {instanceMSL.loading ? (
                    <CircularProgress color='success' />
                  ) : !instanceMSL.error ? (
                    <a
                      style={{ textDecoration: 'none', color: '#407BFF' }}
                      href={instanceMSL.url}
                      download={order.idTucomida ? `FACTURA-MSL-${order.idTucomida}.pdf` : `FACTURA-MSL-${order._id}.pdf`}
                      // target='_blank'
                      // rel='noreferrer'
                    >
                      MSL
                    </a>
                  ) : (
                    <div>Err</div>
                  )}
                </div> */}
              </div>
            </div>
          </button>
          <div
            style={{ cursor: 'pointer', width: '150px' }}
            className='tableHeader__body__btn active rightSide'
            onClick={() => setAddProductModal(true)}
          >
            ADD PRODUCT
          </div>
          <Modal open={addProductModal} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '300px',
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}
            >
              <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <TextField label='Search' value={searchAddProd} onChange={(e) => setSearchAddProd(e.target.value)} fullWidth></TextField>
                <div
                  onClick={() => {
                    setSearchAddProd('')
                  }}
                  style={{
                    position: 'absolute',
                    right: '10%',
                    cursor: 'pointer',
                    fontSize: '20px',
                    fontWeight: 'lighter',
                    marginTop: '10px',
                    border: '1px solid #000000',
                    padding: '5px',
                    borderRadius: '5px',
                  }}
                >
                  х
                </div>
              </Box>

              {searchAddProd ? (
                <Box
                  sx={{
                    p: 2,
                    height: '300px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    width: '100%',
                  }}
                >
                  {filtredProductsAdd.map((product, index) => {
                    if (index < 25) {
                      return (
                        <MenuItem
                          sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
                          key={product.id}
                          value={product}
                          onClick={() => addSelectedProduct(product)}
                        >
                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <input
                              className='checkbox'
                              type='checkbox'
                              style={{ cursor: 'pointer' }}
                              key={product.id}
                              checked={selectedProducts.find((elem) => elem._id === product._id)}
                            />
                            <img width={50} height={50} src={product.img} alt={product.name} />
                          </div>
                          <div>{product.name ? product.name : ''}</div>
                          <div>{product.marketPriceCP ? product.marketPriceCP : 0} &euro;</div>
                          {selectedProducts.find((p) => p._id === product._id) && (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                              <Button
                                variant='outlined'
                                size='small'
                                sx={{
                                  cursor: 'pointer',
                                  fontWeight: 'bold',
                                  border: '1px solid #000',
                                  color: '#000',
                                  // padding: '0px',
                                  width: 'max-content',
                                  // borderRadius: '5px',
                                }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  incrementQuantity(product._id)
                                }}
                              >
                                +1
                              </Button>
                              <p>{selectedProducts.find((p) => p._id === product._id)?.quantity}</p>
                              <Button
                                variant='outlined'
                                size='small'
                                sx={{
                                  cursor: 'pointer',
                                  fontWeight: 'bold',
                                  border: '1px solid #000',
                                  color: '#000',
                                  // padding: '0px',
                                  width: 'max-content',
                                  // borderRadius: '5px',
                                }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  decrementQuantity(product._id)
                                }}
                              >
                                -1
                              </Button>
                            </div>
                          )}
                        </MenuItem>
                      )
                    }
                  })}
                </Box>
              ) : (
                <Box sx={{ p: 2, height: '300px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', overflowY: 'scroll' }}>
                  {uniqueCategories.map((category) => (
                    <div key={category.value}>
                      <div
                        style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
                        onClick={() => handleCategoryToggle(category.value)}
                      >
                        <p>{category.label}</p>
                        <p>{expandedCategories.includes(category.value) ? '-' : '+'}</p>
                      </div>
                      {expandedCategories.includes(category.value) && (
                        <div>
                          {groups[category.value]
                            ?.sort((a, b) => a.name.localeCompare(b.name))
                            .map((product, index) => (
                              <div style={{ display: 'flex', justifyContent: 'space-between' }} key={product._id}>
                                <input
                                  style={{ alignSelf: 'center' }}
                                  checked={selectedProducts.find((elem) => elem._id === product._id)}
                                  className='checkbox'
                                  type='checkbox'
                                  onChange={() => addSelectedProduct(product)}
                                />
                                <div style={{ display: 'flex', alignItems: 'center', width: '90%', justifyContent: 'space-between' }}>
                                  <img width={50} height={50} src={product.img} alt={product.name} />
                                  <p>{product.name}</p>
                                  <p>{product.marketPriceCP ? product.marketPriceCP.toFixed(2) : 'not set'} €</p>
                                  {selectedProducts.find((p) => p._id === product._id) && (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                      <Button
                                        variant='outlined'
                                        size='small'
                                        sx={{
                                          cursor: 'pointer',
                                          fontWeight: 'bold',
                                          border: '1px solid #000',
                                          color: '#000',
                                          // padding: '0px',
                                          // width: 'max-content',
                                          // borderRadius: '5px',
                                          minWidth: 'max-content',
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          e.stopPropagation()
                                          incrementQuantity(product._id)
                                        }}
                                      >
                                        +1
                                      </Button>
                                      <p>{selectedProducts.find((p) => p._id === product._id)?.quantity}</p>
                                      <Button
                                        variant='outlined'
                                        size='small'
                                        sx={{
                                          cursor: 'pointer',
                                          fontWeight: 'bold',
                                          border: '1px solid #000',
                                          color: '#000',
                                          // padding: '0px',
                                          width: 'max-content',
                                          // borderRadius: '5px',
                                          minWidth: 'max-content',
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          e.stopPropagation()
                                          decrementQuantity(product._id)
                                        }}
                                      >
                                        -1
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  ))}
                  {/* {filtredProducts?.map((product, index) => (
                    
                  ))} */}
                </Box>
              )}

              <Button
                variant='contained'
                size='small'
                style={{ width: '100%' }}
                onClick={() => {
                  changeItemsinOrder()
                  setAddProductModal(false)
                }}
              >
                Add
              </Button>
            </Box>
          </Modal>
        </div>
      )}

      <CustomAlert open={open} setOpen={setOpen} info={'Ahora el pedido está redactando:'} additionalInfo={info} action={autoStopEditting} />
    </div>
  )
}
