import { Box, Container } from '@mui/material'
import React, { useState, useEffect } from 'react'
import prewiew from '../../assets/png/prewiew.png'

const NoAccess = () => {
  return (
    <Box sx={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <h2 style={{ textAlign: 'center', fontSize: '30px' }}>No Access. Ask admin</h2>
      <img src={prewiew} alt='noAccess' onClick={() => window.open('https://t.me/TPOBCN', '_blank')} />
    </Box>
  )
}

export default NoAccess
