import React, { useState, useEffect } from 'react'
import { SiteSettings } from '../Profile/SitesSettings/SiteSettings'

export const TestFunc = () => {
  const site = JSON.parse(localStorage.getItem('site'))

  return site.name === 'TPO' && <SiteSettings />
}

export default TestFunc
