import React, { useState, useEffect } from 'react'
import {
  Container,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Modal,
} from '@mui/material'
import { mainApi } from '../../../store/services/mainApi'
import MultipleSelectChip from '../../Rcomp/MultipleSelectChip/MultipleSelectChip'
import { useDispatch } from 'react-redux'

export const SiteSettings = () => {
  const { data: sites, isLoading } = mainApi.useGetSitesParamsQuery()
  const [updateSiteParams] = mainApi.useUpdateSiteParamsMutation()
  const dispatch = useDispatch()

  // selct site

  const [selectedSite, setSelectedSite] = useState({})

  // Update selectedSite when sites data changes
  useEffect(() => {
    if (sites && sites.length > 0) {
      // Assuming you set selectedSite based on sites data
      setSelectedSite(sites[0]) // For example, setting the first site as selected
    }
  }, [sites])

  // Update siteState when selectedSite changes
  useEffect(() => {
    setSiteState(selectedSite)
  }, [selectedSite])

  const [siteState, setSiteState] = useState({
    _id: '',
    logo: '',
    name: '',
    whatsapp: '',
    telegram: '',
    facturas: [],
    avalible: {
      orders: true,
      contacts: true,
      catalog: true,
      operations: {
        billing: true,
        facturas: true,
        shipment: true,
        juise: true,
        shopping: true,
      },
      messages: true,
      projects: true,
    },
  })

  // Update selectedSite when sites data changes
  useEffect(() => {
    if (sites && sites.length > 0) {
      // Assuming you set selectedSite based on sites data
      setSelectedSite(sites[0]) // For example, setting the first site as selected
    }
  }, [sites])

  // Update siteState when selectedSite changes
  useEffect(() => {
    setSiteState(selectedSite)
  }, [selectedSite])

  const setSite = (key, value) => {
    setSiteState((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const handleSubmit = () => {
    updateSiteParams({
      data: siteState,
    })
      .unwrap()
      .then(() => {
        alert('Settings updated')
        dispatch(mainApi.util.invalidateTags(['SiteParams']))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const [siteStateEmpty, setSiteStateEmpty] = useState({
    _id: '',
    logo: '',
    name: '',
    whatsapp: '',
    telegram: '',
    facturas: [],
    avalible: {
      orders: true,
      contacts: true,
      catalog: true,
      operations: {
        billing: true,
        facturas: true,
        shipment: true,
        juise: true,
        shopping: true,
      },
      messages: true,
      projects: true,
    },
  })

  const setSiteEmpty = (key, value) => {
    setSiteStateEmpty((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const handleSubmitEmpty = () => {
    delete siteStateEmpty._id
    console.log(siteStateEmpty)
  }

  //modal state

  const [openSite, setOpenSite] = useState(false)

  return (
    <Container sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#fff', mt: '20px', borderRadius: '10px', border: '1px solid #ccc' }}>
      {!isLoading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant='h5'>Site Settings</Typography>
          <Select
            sx={{
              width: '80px',
            }}
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={selectedSite}
          >
            {sites
              ? sites.map((site) => (
                  <MenuItem key={site?._id} onClick={() => setSelectedSite(site || {})} value={site || {}}>
                    {site?.name}
                  </MenuItem>
                ))
              : null}
          </Select>
          <SiteSettingsNew siteState={siteState} setSite={setSite} handleSubmit={handleSubmit} />
          <Modal open={openSite} onClose={() => setOpenSite(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}
            >
              <SiteSettingsNew siteState={siteStateEmpty} setSite={setSiteEmpty} handleSubmit={handleSubmitEmpty} />
            </Box>
          </Modal>
          <Button onClick={() => setOpenSite(true)} sx={{ marginBottom: '10px' }} variant='contained'>
            Create New Site <span style={{ marginLeft: '5px', color: 'red' }}>Dev</span>
          </Button>
        </Box>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </Container>
  )
}

export const SiteSettingsNew = ({ handleSubmit, siteState, setSite }) => {
  console.log('siteState', siteState, 'setSite', setSite, 'handleSubmit', handleSubmit)
  return (
    <FormControl
      sx={{
        m: 1,
        minWidth: 300,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: '10px',
        alignItems: 'center',
      }}
    >
      <TextField
        sx={{ width: '200px' }}
        label='Name'
        variant='outlined'
        InputLabelProps={{ shrink: true }}
        value={siteState.name}
        onChange={(e) => setSite('name', e.target.value)}
      />
      <TextField
        variant='outlined'
        InputLabelProps={{ shrink: true }}
        sx={{ width: '200px' }}
        label='Whatsapp'
        value={siteState.whatsapp}
        onChange={(e) => setSite('whatsapp', e.target.value)}
      />
      <TextField
        variant='outlined'
        InputLabelProps={{ shrink: true }}
        sx={{ width: '200px' }}
        label='Telegram'
        value={siteState.telegram}
        onChange={(e) => setSite('telegram', e.target.value)}
      />
      <TextField
        variant='outlined'
        InputLabelProps={{ shrink: true }}
        sx={{ width: '200px' }}
        label='Logo'
        value={siteState.logo}
        onChange={(e) => setSite('logo', e.target.value)}
      />

      {/* <MultipleSelectChip
            label='Facturas'
            values={siteState.facturas || []}
            setValues={(values) => setSite('facturas', values)}
            names={siteState.facturas ? siteState.facturas.map((f) => f.name) : []}
          /> */}
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: '10px', alignItems: 'center' }}>
        <Typography sx={{ marginBottom: '10px' }} variant='h6'>
          Available
        </Typography>
        <FormGroup sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'space-between' }} row>
          {siteState.avalible &&
            Object.entries(siteState.avalible).map(([key, value]) => {
              if (key === 'operations') {
                return Object.entries(value).map(([operationKey, operationValue]) => (
                  <FormControlLabel
                    key={operationKey}
                    control={
                      <Checkbox
                        checked={value[operationKey]}
                        onChange={(e) =>
                          setSite('avalible', {
                            ...siteState.avalible,
                            operations: { ...siteState.avalible.operations, [operationKey]: e.target.checked },
                          })
                        }
                      />
                    }
                    label={operationKey}
                  />
                ))
              } else {
                return (
                  <FormControlLabel
                    key={key}
                    control={<Checkbox checked={value} onChange={(e) => setSite('avalible', { ...siteState.avalible, [key]: e.target.checked })} />}
                    label={key}
                  />
                )
              }
            })}
        </FormGroup>
        <Button onClick={() => handleSubmit()} sx={{ marginTop: '10px' }} variant='contained'>
          Save
        </Button>
      </Box>
    </FormControl>
  )
}
