import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Modal, Box, TextField } from '@mui/material'

export default function CustomAlert({ open, setOpen, confirm, info, additionalInfo, action, passValue }) {
  const handleClose = () => {
    setOpen(false)
  }

  const handleDischarge = () => {
    setPassModal(true)
  }

  const [passModal, setPassModal] = React.useState(false)
  const [pass, setPass] = React.useState('')

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{info}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{additionalInfo}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Modal open={passModal} onClose={() => setPassModal(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
          <Box
            sx={{
              width: 250,
              height: 100,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'column',
              backgroundColor: '#fff',
              padding: '20px',
            }}
          >
            <TextField variant='outlined' label='Password' value={pass} onChange={(e) => setPass(e.target.value)} />
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                action(pass)
                setPassModal(false)
                handleClose()
              }}
            >
              Confirm
            </Button>
          </Box>
        </Modal>
        <Button onClick={handleDischarge}>Discharge</Button>
        <Button onClick={handleClose} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  )
}
