import React, {useMemo, useState} from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Box, Checkbox, Link, MenuItem, Modal, OutlinedInput, Select, Switch, Typography} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import TableContainer from "@mui/material/TableContainer";
import {
    findProfit,
    findTotal
} from "../../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTableBody/OrdersChangeTableBodyItem/OrderChangeTableBodyItem";

import { ReactComponent as Like } from '../../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../../assets/svg/inProgress.svg'
import { ReactComponent as Production } from '../../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../../assets/svg/Congelador.svg'
import { ReactComponent as Plus } from '../../../assets/svg/Plus.svg'
import {mainApi} from "../../../store/services/mainApi";

const JuiceProductionListItem = ({item, statuses, mobileScreen, rowStyle, checkBoxStyle, rowStyleMap, switchStyle, statusBackground, selectedItems, allItemsArray, handleTopLevelSelectAlone, handleAllItemsSelect, setSwitchChecker, switchChecker, rows, openRows, toggleRowOpen, handleNestedSelect}) => {



    const { data: orderData, isLoading4 } = mainApi.useGetOrdersByIdQuery(item._id)
    const [changeItems] = mainApi.useChangeItemsInOrderMutation()
    const [changeDeliveryTime] = mainApi.useChangeOrderDeliveryTimeMutation()


    const [currentDeliveryTime, setCurrentDeliveryTime] = useState(item.deliveryTime.includes("PLT") ? "PLT" : "PLM")

    const findTotal = (marketPrice, ourPrice, IVA, quantity) => {

        const total = marketPrice * quantity

        return !isNaN(total) ? total.toFixed(2) : '0.00'
    }


    const findProfit = (marketPrice, ourPrice, IVA, quantity) => {
        if (isNaN(marketPrice)) {
            return '0.00'
        }

        const revenue = marketPrice * quantity
        const cost = ourPrice * quantity

        const profit = revenue - cost

        return profit.toFixed(2)
    }



    const [statusModal, setStatusModal] = useState(false)


    const [updateStatus, setUpdateStatus] = useState(statusBackground(item.items.status.name))


    document.addEventListener("click", () => setStatusModal(false));
    const handleOpenModal = () => {
        setStatusModal(!statusModal)
    }


    const setChangeDeliveryTime = (orderData, order, value) => {
        try {
            console.log("Tis", orderData, order, value)
            changeDeliveryTime({
                    ...orderData,
                    deliveryTime: value == "PLT" ? "PLT🌙" : "PLM☀️"
            })
                .unwrap()
                .then((res) => {
                    if(!res.acknowledged){
                        alert("erorr")

                    }
                })

        }catch (e) {
            console.log(e)
        }


    }



    const setChangeStatus = (orderData, order, status) => {
        try {
            setUpdateStatus(statusBackground(status?.name))
            changeItems({
                elem: {
                    ...orderData,
                    items: [...orderData.items.filter(el => el._id != order.items._id), {...order.items, status}],
                },
            })
                .unwrap()
                .then((res) => {
                    if(!res.acknowledged){
                        alert("erorr")

                    }
                })

        }catch (e) {
            console.log(e)
        }


    }



    return (
        <React.Fragment key={item._id + item.items._id}>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell sx={{...rowStyleMap, padding: "0", textAlign: "center"}}>
                    <Checkbox
                        sx={checkBoxStyle}

                        // Checked if all nested orders are selected
                        checked={
                            selectedItems.some(i =>
                                (i._id + i.items._id) === (item._id + item.items._id)
                            )
                        }
                        onChange={(event) => handleTopLevelSelectAlone(event, item)}
                    />
                </TableCell>

                <TableCell align="right"
                           sx={{...rowStyleMap, color: "green", fontWeight: 'bold', padding: "0px", zIndex: "9999"}}>
                    <div
                        style={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '7px',
                            background: '#fff',
                            border: '2px solid #06c0b0',

                            color: '#FFF',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            top: '1px',
                            cursor: 'pointer',

                            //   background: status ? '#06c0b0' : '#fff',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpenModal()

                        }}
                    >
                        {updateStatus}

                        <Modal open={statusModal} onClose={handleOpenModal}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 300,
                                    height: 300,
                                    bgcolor: 'background.paper',
                                    border: '2px solid #000',
                                    boxShadow: 24,
                                    p: 4,
                                }}
                            >
                                <div
                                    className='itemStatus'
                                    style={{
                                        display: statusModal ? 'grid' : 'none',
                                        gridTemplateColumns: '1fr 1fr',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                        height: '300px',
                                        position: 'relative',
                                        top: '50%',
                                        left: '0',
                                        transform: 'translate(0, -50%)',
                                        border: 'none',
                                        boxShadow: 'none',
                                    }}
                                >
                                    {/*<div*/}
                                    {/*    style={{border: '1px solid #000'}}*/}
                                    {/*    onClick={() => setChangeStatus(orderData, item, statuses.find((status) => status._id === '6584a9da0ca272b8a1c94df4'))}*/}
                                    {/*    className='itemStatus__item'*/}
                                    {/*>*/}
                                    {/*    <Like width={24} height={24}/>*/}
                                    {/*    <span>READY</span>*/}
                                    {/*</div>*/}
                                    {/*<div*/}
                                    {/*    style={{border: '1px solid #000'}}*/}
                                    {/*    onClick={() => setChangeStatus(orderData, item, statuses.find((status) => status._id === '6584ab230ca272b8a1c94df9'))}*/}
                                    {/*    className='itemStatus__item'*/}
                                    {/*>*/}
                                    {/*    <Delivery width={24} height={24}/>*/}
                                    {/*    <span>SHOPPING</span>*/}
                                    {/*</div>*/}
                                    <div
                                        style={{border: '1px solid #000'}}
                                        onClick={() => setChangeStatus(orderData, item, statuses.find((status) => status._id === '65a1cd7688c2e6224aa96662'))}
                                        className='itemStatus__item'
                                    >
                                        <p>👍</p>
                                        <span>Confirmar</span>
                                    </div>
                                    {/*<div*/}
                                    {/*    style={{border: '1px solid #000'}}*/}
                                    {/*    onClick={() => setChangeStatus(orderData, item, statuses.find((status) => status._id === '6584aad40ca272b8a1c94df7'))}*/}
                                    {/*    className='itemStatus__item'*/}
                                    {/*>*/}
                                    {/*    /!* <Checking width={24} height={24} /> *!/*/}
                                    {/*    <p>👁️</p>*/}
                                    {/*    <span>CHECKING</span>*/}
                                    {/*</div>*/}
                                    <div
                                        style={{border: '1px solid #000'}}
                                        onClick={() => setChangeStatus(orderData, item, statuses.find((status) => status._id === '6584ab060ca272b8a1c94df8'))}
                                        className='itemStatus__item'
                                    >
                                        <Production width={24} height={24}/>
                                        <span>PRODUCTION</span>
                                    </div>

                                    <div
                                        style={{border: '1px solid #000'}}
                                        onClick={() => setChangeStatus(orderData, item, statuses.find((status) => status._id === '658efe96c582a56609d72de8'))}
                                        className='itemStatus__item'
                                    >
                                        <CONGEDOR width={24} height={24}/>
                                        <span>CONGELADOR</span>
                                    </div>
                                    <div
                                        style={{border: '1px solid #000'}}
                                        onClick={() => setChangeStatus(orderData, item, statuses.find((status) => status._id === '6584aa570ca272b8a1c94df6'))}
                                        className='itemStatus__item'
                                    >
                                        <p>⛔</p>
                                        <span>NOT AVAILABLE</span>
                                    </div>
                                    <div
                                        style={{border: '1px solid #000'}}
                                        onClick={() => setChangeStatus(orderData, item, statuses.find((status) => status._id === '6584aa210ca272b8a1c94df5'))}
                                        className='itemStatus__item'
                                    >
                                        <InProgress width={24} height={24}/>
                                        <span>PARCIAL</span>
                                    </div>
                                    {/*<div*/}
                                    {/*    style={{border: '1px solid #000'}}*/}
                                    {/*    onClick={() => setChangeStatus(orderData, item, statuses.find((status) => status._id === '65959de07503ef32164ba38c'))}*/}
                                    {/*    className='itemStatus__item'*/}
                                    {/*>*/}
                                    {/*    <p>🔙</p>*/}
                                    {/*    <span>DEVOLUCION</span>*/}
                                    {/*</div>*/}

                                    {/*<div*/}
                                    {/*    style={{border: '1px solid #000'}}*/}
                                    {/*    onClick={() => setChangeStatus(orderData, item, statuses.find((status) => status._id === '66967e23563e309ef4688dba'))}*/}
                                    {/*    className='itemStatus__item'*/}
                                    {/*>*/}
                                    {/*    <p>📌</p>*/}
                                    {/*    <span>RESERVED</span>*/}
                                    {/*</div>*/}

                                    <div
                                        onClick={() => setChangeStatus(orderData, item, {})}
                                         className='itemStatus__add'>
                                        Delete
                                        <Plus
                                            width={15}
                                            height={15}
                                            style={{
                                                position: 'relative',
                                                // top: '1px',
                                                left: '12px',
                                            }}
                                        />
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                </TableCell>


                <TableCell sx={rowStyleMap} component="th" scope="row">
                    {!switchChecker && <img src={item.items.img} style={{width: "auto", height: '50px'}}/>}
                </TableCell>
                <TableCell sx={rowStyleMap} component="th" scope="row">
                    {item.items.name.replace("Zumos Frescos", "").replace("bot/plast.", "")}
                </TableCell>


                <TableCell sx={rowStyleMap} align="right">
                    <Link target="_blank" href={"/admin/orders/" + item._id}>
                        {item.tradeName || item.userAcc.tradeName || item.userAcc.name}
                    </Link>
                </TableCell>
                <TableCell sx={rowStyleMap}
                           align="right">{item.items.sku.includes("plast") ? "Plast" : "Crist"}</TableCell>

                <TableCell sx={rowStyleMap}
                           align="right">{item.items.quantity + " " + item.items.unit}</TableCell>
                {/*<TableCell align="right" sx={{...rowStyleMap, color: "green", fontWeight: 'bold'}}>{statusBackground(item.items.status.name) || "#New"}</TableCell>*/}


                <TableCell sx={rowStyleMap} align="right">
                    {parseFloat(item.items.quantity * item.items.marketPriceCP).toFixed(2) + "€"}
                </TableCell>
                {/*<TableCell sx={rowStyleMap} align="right">*/}
                {/*    {parseFloat(item.items.quantity * item.items.ourPrice).toFixed(2) + "€"}*/}
                {/*</TableCell>*/}
                {/*<TableCell sx={rowStyleMap} align="right">*/}
                {/*    {((findProfit(parseFloat(item.items.marketPriceCP), parseFloat(item.items.ourPrice), item.items.IVA, parseFloat(item.items.quantity)))) + "€"}*/}
                {/*</TableCell>*/}
                {/*<TableCell sx={rowStyleMap} align="right">*/}

                {/*    {((findProfit(parseFloat(item.items.marketPriceCP), parseFloat(item.items.ourPrice), item.items.IVA, parseFloat(item.items.quantity)) / findTotal(parseFloat(item.items.marketPriceCP), parseFloat(item.items.ourPrice), item.items.IVA, parseFloat(item.items.quantity))) * 100).toFixed(2) + "%"}*/}
                {/*</TableCell>*/}
                <TableCell sx={rowStyleMap}
                       align="right">{item.deliveryDate.split(',')[0]}

                </TableCell>

                <TableCell sx={rowStyleMap} align="right">
                <Select
                    labelId="demo-simple-checkbox-label"
                    id="demo-simple-checkbox"
                    color="success"
                    value={currentDeliveryTime}
                    onChange={(e) =>
                    {
                        setCurrentDeliveryTime(e.target.value)
                        setChangeDeliveryTime(orderData, e.target.value)

                    }}
                >
                    <MenuItem value={"PLM"}>PLM☀️</MenuItem>
                    <MenuItem value={"PLT"}>PLT🌙</MenuItem>
                </Select>
                </TableCell>

                {/*<TableCell align="right">{item.items.sku}</TableCell>*/}

            </TableRow>
        </React.Fragment>
    );
};

export default JuiceProductionListItem;